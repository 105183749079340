import React from 'react';
import ReactDOM from 'react-dom';
import EngagmicTimeline from './EngagmicTimeline';
import 'bootstrap/dist/css/bootstrap.min.css';


import './index.css';
import 'react-dropzone-uploader/dist/styles.css'
import NewTimeliner from './NewTimeliner';
// import 'video-react/dist/video-react.css'; // import css
import ReactVideo from './ReactVideo';




ReactDOM.render(
  <React.StrictMode>
    {/* <ReactVideo /> */}
    
    <EngagmicTimeline />
    {/* <NewTimeliner /> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

