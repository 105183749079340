import React, { Component } from 'react'
import { Timeliner } from '@nishimasa/timeliner/build/timeliner.min'


export class NewTimeliner extends Component {
    render() {
        var target = {
            name1: 1,
            name2: 2,
            name3: 3
        };
        
        // initialize timeliner
        var timeliner = new Timeliner(target);
        timeliner.addLayer('name1');
        timeliner.addLayer('name2');
        timeliner.addLayer('name3');

        console.log(timeliner);

        return (
            <div>
                
            </div>
        )
    }
}

export default NewTimeliner
