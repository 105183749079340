import React, { Component } from 'react';
import Dropzone from 'react-dropzone-uploader';


class VideoDrop extends Component {
    state = {}
        
        duration = null;
        // called every time a file's `status` changes
        handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) 
        console.log(meta.duration);
        this.duration = meta.duration;

        }

        // receives array of files that are done uploading when submit button is clicked
        handleSubmit = (files, allFiles) => {
            console.log(files.map(f => f.meta))
            allFiles.forEach(f => f.remove())
        
            
        }
      
        render() {
            return (
                <Dropzone
                    onChangeStatus={this.handleChangeStatus}
                    onSubmit={this.handleSubmit}
                    accept="image/*,audio/*,video/*"
                />
            );
        }
}

    export default VideoDrop;