import React, {Component} from 'react'
import ReactPlayer from 'react-player'

class Player extends Component {
    state = {  }

    myRef = React.createRef();

    progressHandler = (pars) => {
        console.log(pars);
    }

    handleDuration = (obj) => {
        console.log("obj => " + obj);
        // this.props.getDuration(obj)
    }

    // handleSeek = (seeked) => {
    //     console.log(seeked);
    // }
    
    handleClick = (sec) => {
        this.myRef.current.seekTo(sec, 'seconds');
    }


    render() { 
        return ( 
            <>
                <ReactPlayer ref={this.myRef} url='Videos/j.mp4' controls={true} onDuration={this.handleDuration} onSeek={this.handleSeek} onProgress={this.progressHandler} volume={0}/>
            </>
         );
    }
}
 
export default Player;