import React, { Component } from "react";
import { DataSet, Timeline as Vis } from 'vis-timeline/standalone';
import FeatherIcon from 'feather-icons-react';
import ReactPlayer from 'react-player'


import TimelineModel from './TimelineModel';
import { server } from './config';
import VideoDrop from "./VideoDrop";
import Player from './Player';
import './line.css';
import Edit_Bar from "./Edit_Bar";
import Draggable from "react-draggable";
import ClapprComponent from "./ClapprComponent";
import Clappr from "clappr";

class EngagmicTimeline extends Component {
	// myRef = React.createRef();
	timeline = null;
	state = {
		selectedItems: [],
		showAddFilterDialog: false,
		duration: '00:00:00,000',
		myDuration: 66,
		dragging: false,
		styles: {},
		interaction: null,
		interactionTimeline: null,
		items: [],
		groups: [
			// { id: 1, content: 'Group 1', title: 'g1', visible: true, style: "color: red; background-color: lightblue;" },
			// { id: 2, content: 'Group 2', title: 'g2', visible: true, style: "color: red; background-color: pink;" },
			// { id: 3, content: 'Group 2', title: 'g3', visible: true, style: "color: red; background-color: yellow;" },
		],
	};

	handleAdd = () => {
		alert("hiiiii");
	}

	componentDidMount() {
		let that = this;

		let addTextHandler = (time) => {
			let myId = Date.now()
			this.state.items.push({ id: myId, content: `item ${Math.floor(Math.random() * 10)}`, start: new Date(1970, 0, 1, 0, 0, time.current, 0), group: myId, end: new Date(1970, 0, 1, 0, 0, time.current + 2, 0) });
			let newItems = this.state.items;

			this.state.groups.push({ id: myId, content: 'Group', title: 'g1', visible: true, style: "color: red; background-color: lightblue;" });
			let newGroups = this.state.groups;

			this.timeline.setData({
				items: newItems,
				groups: newGroups,
			});
			this.setState({ items: newItems, groups: newGroups, })

		}

		/***************** Start Clappr.UIContainerPlugin.extend **************/
		let HelloWorld = Clappr.UIContainerPlugin.extend({
			name: 'hello_world',
			initialize: function () {
				this.render();
				// !window.playerUtilities ? window.playerUtilities = {} : null;
				// this.utilities = window.playerUtilities;
				window.show = () => {
					this.show();
				}
				window.hide = () => {
					this.hide();
				}
			},
			bindEvents: function () {
				// this.listenTo(this.container, Clappr.Events.CONTAINER_PAUSE, this.show);
				// this.listenTo(this.container, Clappr.Events.CONTAINER_PLAY, this.hide);
				this.listenTo(this.container, Clappr.Events.CONTAINER_TIMEUPDATE, this.timeUpdate);
				// this.listenToOnce(this.container, Clappr.Events.CONTAINER_TIMEUPDATE, this.timeUpdate);
			},
			hide: function () {
				this.$el.hide();
			},
			show: function () {
				this.$el.show();
			},
			timeUpdate: function (e) {

				if (document.getElementById('button')) {
					document.getElementById('button').onclick = () => {
						addTextHandler(e);
					}
				}


				// if (that.state.items[0] && (e.current >= that.state.items[0].start.getSeconds() && e.current < that.state.items[0].end.getSeconds())) {
				// 	this.show();
				// } else {
				// 	this.hide();
				// }

				let x = that.state.items.some((item, index) => {
					if (e.current > item.start.getSeconds() && e.current < item.end.getSeconds()) {
						// this.show();
						console.log("show work");
						return true;
					} else {
						// this.hide();
						console.log("hide work");
						// return false;
					}
				});
				if (x) {
					this.show();
				} else {
					this.hide();
				}
				// console.log(x);

			},
			render: function () {
				this.$el.html('Hello World!');
				this.$el.css('font-size', '100px');
				this.$el.css('color', 'white');
				this.$el.css('background-color', 'red');
				this.$el.css('position', 'relative');
				this.container.$el.append(this.$el);
				// const newDiv = document.createElement("p");
				// newDiv.setAttribute("id", "mu");
				// const newContent = document.createTextNode("Hi there");
				// newDiv.appendChild(newContent);
				// this.container.$el.append(newDiv);
				console.log(this);
				console.log(this.el);
				console.log(this.$el);
				console.log(this.container);
				console.log(this.container.el);
				console.log(this.container.$el);
				console.log(this.container.getCurrentTime());
				// console.log(this.container.$('#mu'));
				this.hide();
				return this;
			}
		});


		/***************** End Clappr.UIContainerPlugin.extend **************/


		/*********************** Start Clappr player ************************/

		this.clappr_player = new Clappr.Player({
			parentId: `#video`,
			source: "Videos/j.mp4",
			mute: true,
			height: 360,
			width: 640,
			plugins: [HelloWorld],
			playbackRateConfig: {
				defaultValue: 1,
				options: [
					{ value: 0.5, label: "0.5x" },
					{ value: 1, label: "1x" },
					{ value: 2, label: "2x" }
				],
			},
		});

		/************************ End Clappr player *************************/

		const container = document.getElementById('timeline');
		// console.log(container);
		const options = {
			autoResize: true,
			timeAxis: { scale: 'second', step: 1 },
			horizontalScroll: true,
			zoomable: false,
			selectable: true,
			orientation: 'top',
			min: new Date(1970, 0, 1, 0, 0, 0),
			max: new Date(1970, 0, 1, 0, 0, container.attributes.mytime.value, 0),
			showCurrentTime: true,
			multiselect: true,
			multiselectPerGroup: true,
			stack: false,
			start: new Date(1970, 0, 1, 0, 0, 0, 0),
			end: new Date(1970, 0, 1, 0, 0, 15, 999),
			zoomMin: 100,
			zoomMax: 21600,
			editable: {
				add: true,         // add new items by double tapping
				updateTime: true,  // drag items horizontally
				updateGroup: true, // drag items from one group to another
				remove: true,       // delete an item by tapping the delete button top right
				overrideItems: true,  // allow these options to override item.editable
			},
			onAdd: this.handleAdd,
			onMove: this.onMove,
			onMoving: this.onMoving,
			onRemove: this.onRemove,
			format: {
				minorLabels: {
					millisecond: 'SSS [ms]',
					second: 'HH:mm:ss',
					minute: '',
					hour: '',
					weekday: '',
					day: '',
					week: '',
					month: '',
					year: ''
				},
				majorLabels: {
					millisecond: '',
					second: '',
					minute: '',
					hour: '',
					weekday: '',
					day: '',
					week: '',
					month: '',
					year: ''
				}
			},

		};

		// const items = [
		// 	{ id: 1, content: "item 1", start: new Date(1970, 0, 1, 0, 0, 1, 0), group: 2, end: new Date(1970, 0, 1, 0, 0, 2, 0) },
		// 	{ id: 2, content: "item 2", start: new Date(1970, 0, 1, 0, 0, 5, 0), end: new Date(1970, 0, 1, 0, 0, 6, 0), group: 2 },
		// 	{ id: 3, content: "item 3", start: new Date(1970, 0, 1, 0, 0, 4, 0), end: new Date(1970, 0, 1, 0, 0, 6, 0), group: 1, style: "color: red; background-color: pink;", type: 'range' },
		// 	{ id: 4, content: "item 4", start: new Date(1970, 0, 1, 0, 0, 9, 0), end: new Date(1970, 0, 1, 0, 0, 11, 0), group: 3, style: "color: red; background-color: pink;", type: 'range' },
		// 	{ id: 5, content: "item 5", start: new Date(1970, 0, 1, 0, 0, 4, 0), end: new Date(1970, 0, 1, 0, 0, 6, 0), group: 3, style: "color: red; background-color: pink;", type: 'range' },
		// 	{ id: 6, content: "item 6", start: new Date(1970, 0, 1, 0, 0, 10, 0), type: "point", group: 1, end: new Date(1970, 0, 1, 0, 0, 11, 0) },
		// ];


		// const groups = [
		// 	{ id: 1, content: 'Group 1', title: 'g1', visible: true, style: "color: red; background-color: lightblue;" },
		// 	{ id: 2, content: 'Group 2', title: 'g2', visible: true, style: "color: red; background-color: pink;" },
		// 	{ id: 3, content: 'Group 2', title: 'g3', visible: true, style: "color: red; background-color: yellow;" },
		// ];

		this.timeline = new Vis(container, this.state.items, this.state.groups, options);
		this.timeline.addCustomTime(new Date(1970, 0, 1, 0, 0, 0));
		this.timeline.setCustomTimeTitle(this.timeline.getCustomTime());
		this.timeline.on('select', this.onSelect);
		this.timeline.on('timechange', this.onTimeChange);
		this.timeline.on('moving', this.onMoving);
		this.timeline.on('move', this.onMove);
	}

	/********************** End componentDidMount ************************/

	onRemove = (item) => {
		let newItems = [...this.state.items];
		let newGroups = [...this.state.groups];
		let idx = newItems.findIndex(e => e.id === item.id);
		newItems.splice(idx, 1);
		newGroups.splice(idx, 1);
		console.log(idx);
		this.timeline.setData({
			items:newItems,
			groups:newGroups,
		})
		this.setState({ items: newItems, groups:newGroups });
	}

	onSelect = (properties) => {
		this.setState({ selectedItems: properties.items });
	}

	onTimeChange = (event) => {
		this.timePointerHandler(event.time.getSeconds() + (event.time.getMinutes() * 60) + (event.time.getHours() * 60 * 60));
		// console.log(event.event.center);
	}

	timePointerHandler = (sec) => {
		// console.log(this.myRef);
		// this.myRef.current.seekTo(sec, 'seconds');
		this.clappr_player.seek(sec);
	};

	onMoving = (item, callback) => {
		// console.log(item.start);
		let newItems = this.state.items.map((ele) => {
			if (ele.id === item.id) {
				ele.start = new Date(1970, 0, 1, 0, 0, item.start.getSeconds(), 0);
				ele.end = new Date(1970, 0, 1, 0, 0, item.end.getSeconds(), 0);
			}
			return ele;
		})
		this.setState({ items: newItems });
		this.timeline.setData({
			items: newItems,
			// groups: groups,
		});

	}

	onMove = (item) => {

		// item.className = 'video';
		// item = this.itemMove(item);
		// if (item !== null) {
		// 	const itemPath = item.id.split(':');
		// 	const url = `${server.apiUrl}/project/${this.props.project}/item/move`;
		// 	const params = {
		// 		method: 'PUT',
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 		},
		// 		body: JSON.stringify({
		// 			track: itemPath[0],
		// 			trackTarget: item.group,
		// 			item: Number(itemPath[1]),
		// 			time: TimelineModel.dateToString(item.start),
		// 		}),
		// 	};

		// 	fetch(url, params)
		// 		.then(response => response.json())
		// 		.then(data => {
		// 			if (typeof data.err !== 'undefined') {
		// 				alert(`${data.err}\n\n${data.msg}`);
		// 			}
		// 			else {
		// 				if (itemPath[0] === item.group) { // Same track
		// 					this.props.loadData();
		// 				}
		// 				else { // Moving between tracks
		// 					const trackType = (item.group.includes('audio')) ? 'audio' : 'video';
		// 					const prevTrack = TimelineModel.findTrack(this.props.items, itemPath[0]);
		// 					const newTrack = TimelineModel.findTrack(this.props.items, item.group);

		// 					const addTrack = (newTrack.items.length === 0); //
		// 					const delTrack = (TimelineModel.findItem(prevTrack.items, 1) === undefined);

		// 					if (addTrack && delTrack) this.addTrack(trackType, prevTrack.id);
		// 					else if (addTrack) this.addTrack(trackType, null);
		// 					else if (delTrack) this.delTrack(prevTrack.id);
		// 					else this.props.loadData();
		// 				}
		// 			}
		// 		})
		// 		.catch(error => this.props.fetchError(error.message))
		// 		;

		// }
		// console.log(item);
	}

	/***************************** onMove End ***************************/

	// addTextHandler = () => {
	// 	this.state.items.push({ id: Date.now(), content: "item 1", start: new Date(1970, 0, 1, 0, 0, 7, 0), group: 2, end: new Date(1970, 0, 1, 0, 0, 10, 0) });
	// 	let newItems = this.state.items;
	// 	console.log(newItems);
	// 	this.timeline.setData({
	// 		items: newItems,
	// 		// groups: groups,
	// 	});
	// 	this.setState({items:newItems})
	// 	// this.setState({
	// 	// 	interaction: <Draggable
	// 	// 		// axis="x"
	// 	// 		bounds={{ top: -130, left: -290, right: 245, bottom: 75 }}
	// 	// 		handle=".handle"
	// 	// 		defaultPosition={{ x: -20, y: 0 }}
	// 	// 		position={null}
	// 	// 		grid={[1, 1]}
	// 	// 		scale={1}
	// 	// 		onStart={this.handleStart}
	// 	// 		onDrag={this.handleDrag}
	// 	// 		onStop={this.handleStop}
	// 	// 	>
	// 	// 		<textarea className="box" name="" id="" cols="10" rows="4" className="handle"></textarea>
	// 	// 	</Draggable>,
	// 	// 	interactionTimeline: <Draggable
	// 	// 		axis="x"
	// 	// 		handle=".handle"
	// 	// 		defaultPosition={{ x: 0, y: 0 }}
	// 	// 		position={{ x: parseInt(this.clappr_player.getCurrentTime()) * 93, y: 0 }}
	// 	// 		grid={[1, 1]}
	// 	// 		scale={1}
	// 	// 		onStart={this.handleStart}
	// 	// 		onDrag={this.handleDrag}
	// 	// 		onStop={this.handleStop}
	// 	// 	>
	// 	// 		<div className="handle" style={{ width: 60 + 'px', height: 20 + 'px', backgroundColor: 'red' }}></div>
	// 	// 	</Draggable>,
	// 	// });
	// }

	/********************** Start React Player Methods ************************/

	playerProgressHandler = (pars) => {
		console.log(pars);
	}

	playerDurationHandler = (obj) => {
		console.log("obj => " + obj);
		// this.props.getDuration(obj)
	}

	// playerSeekHandler = (seeked) => {
	//     console.log(seeked);
	// }

	/*********************** End React Player Methods *************************/

	render() {
		return (
			<React.Fragment>
				{/* <VideoDrop /> */}
				<Edit_Bar />
				<div className="d-flex justify-content-center 					align-items-center wrap">
					{/* <ReactPlayer ref={this.myRef} url='Videos/j.mp4' controls={true} onDuration={this.playerDurationHandler} onSeek={this.playerSeekHandler} onProgress={this.playerProgressHandler} volume={0} /> */}
					{/* <ClapprComponent
						id="video"
						source="Videos/j.mp4"
						height={360}
						width={640}
						mute={true} /> */}
					<div id="video"></div>
					<div>
						<input id="button" type="submit" name="button" value="enter" />
						{/* <button className="btn" onClick={() => { this.addTextHandler() }}>Text</button> */}
					</div>
					{this.state.interaction}
				</div>
				<div className="container-fluid">
					<div id="timeline" mytime={this.state.myDuration}>
						{this.state.interactionTimeline}
					</div>
					{/* {this.state.interactionTimeline} */}
				</div>
			</React.Fragment>
		);
	}
}

export default EngagmicTimeline;